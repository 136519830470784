'use client';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { GenericModal } from '@/components/modals/GenericModal';
import { DefaultButtonValue, UniModal, UniModalProps } from '@/components/modals/UniModal';

// allows to show a modal window even when there's no host component to render it
// if need be, can be easily updated to store a stack of modal windows

export interface ModalInfo {
  content: ReactNode;
  onShadeClick?: () => void;
  closeByShadeClick?: boolean;
}

export interface ModalContextProps {
  showModal: (modalInfo: ModalInfo) => void;
  closeModal: () => void;
  showModalNew: <const ButtonType = DefaultButtonValue>(
    modalProps: UniModalProps<ButtonType>,
  ) => void;
  setGlass: () => void;
  removeGlass?: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
  showModal: () => {},
  closeModal: () => {},
  showModalNew: () => {},
  setGlass: () => {},
});

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalInfo, setModalInfo] = useState<ModalInfo | null>(null);

  // shows a component in a popup window, with a gray background
  const showModal = useCallback((mi: ModalInfo) => {
    setModalInfo(mi);
  }, []);

  // removes the popup window
  const closeModal = useCallback(() => {
    setModalInfo(null);
  }, []);

  const showModalNew = useCallback<ModalContextProps['showModalNew']>(
    (modalProps) => {
      const { onClose, ...restProps } = modalProps;
      setModalInfo({
        content: (
          <UniModal
            {...restProps}
            onClose={(value) => {
              const shouldClose =
                modalProps.buttons?.find((button) => button.value === value)?.autoClose !== false;
              if (shouldClose) closeModal();
              onClose?.(value);
            }}
          />
        ),
        onShadeClick: () => {
          closeModal();
          onClose?.();
        },
      });
    },
    [closeModal],
  );

  // shows just a gray background, to prevent user from clicking anywhere while a network operation is executing
  const setGlass = useCallback(() => {
    setModalInfo({ content: null });
  }, []);

  // removes the gray background
  const removeGlass = useCallback(() => {
    setModalInfo(null);
  }, []);

  const providedValue = useMemo(() => {
    return {
      showModal,
      closeModal,
      showModalNew,
      setGlass,
      removeGlass,
    };
  }, [showModal, closeModal, showModalNew, setGlass, removeGlass]);

  return (
    <ModalContext.Provider value={providedValue}>
      {children}
      {modalInfo && (
        <GenericModal
          content={modalInfo.content}
          onShadeClick={() => {
            if (modalInfo.closeByShadeClick) closeModal();
            modalInfo.onShadeClick?.();
          }}
        />
      )}
    </ModalContext.Provider>
  );
};

ModalProvider.displayName = 'ModalProvider';

export const useModalContext = () => useContext(ModalContext);

import { ReactNode } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';

type GenericModalProp = {
  content: ReactNode;
  onShadeClick?: () => void;
};

export function GenericModal({ content, onShadeClick }: GenericModalProp) {
  return (
    // TODO: reuse <Overlay/>
    <Dialog as="div" open={true} onClose={() => {}}>
      <div
        className="fixed z-20 bg-overlay left-0 top-0 right-0 bottom-0 flex justify-center items-center"
        onClick={onShadeClick}
      >
        <DialogPanel className="inline-block" onClick={(eo) => eo.stopPropagation()}>
          {content}
        </DialogPanel>
      </div>
    </Dialog>
  );
}
